import React from 'react'

import { useLoaderData } from 'react-router-dom'

import './styles.scss'

export default function Kb() {
  const data = useLoaderData()

  return (
    <>
      <h2>Knowledgebase</h2>
    </>
  )
}

export async function kbLoader({ request, params }) {
  return {
  }
  // return fetch(
  //   `/fake/api/teams/${params.teamId}.json`,
  //   { signal: request.signal }
  // )
}
