import React, { useState } from 'react'

import PropTypes from 'prop-types'

import { CSSTransition, TransitionGroup } from 'react-transition-group'

import useInterval from '../../utils/useInterval'

import Video from '../Video'

import './styles.scss'

/**
 * CONFIGURATION
 */

import LOADER from './items/loader.svg'

const ITEMS = [
  {
    delay: 3000,
    src: require('./items/2023_03_08_lightning.gif'),
  },
  {
    delay: 3000,
    src: require('./items/snow.mp4'),
  },
  {
    delay: 3000,
    src: require('./items/2023_03_06_pathfinding.gif'),
  }
]

const DEFAULT_DELAY = 3000
const FIRST_INDEX = 0

/**
 * END OF CONFIGURATION
 */

const LAST_INDEX = ITEMS.length - 1

export default function Carousel({ className }) {
  const _getElement = ({ delay, src }) => {
    const type = src.substring(src.lastIndexOf('.') + 1)
    switch (type) {
      case 'mp4':
        return (
          <Video
            // <video>
            autoPlay={true}
            className={className}
            loop={true}
            poster={LOADER}
            preload={'auto'}
            // <source>
            src={src}
            type={`video/${type}`}
          />
        )

      case 'gif':
        return (
          <img
            className={className}
            src={src}
            alt={''}
          />)
    }
  }

  const [delay, setDelay] = useState(ITEMS[FIRST_INDEX].delay)
  const [element, setElement] = useState(_getElement(ITEMS[FIRST_INDEX]))
  const [nextIndex, setNextIndex] = useState(FIRST_INDEX)

  const _getNextIndex = () => nextIndex >= LAST_INDEX ? FIRST_INDEX : nextIndex + 1

  useInterval(() => {
    const nextIndex = _getNextIndex()
    const nextItem = ITEMS[nextIndex]
    setDelay(nextItem.delay || DEFAULT_DELAY)
    setElement(_getElement(nextItem)) // @TODO Was hopeful this would fix flash / add fade... does not.
    setNextIndex(nextIndex)
  }, delay)

  return (
    <TransitionGroup className={'carousel-container'}>
      <CSSTransition
        key={nextIndex}
        classNames="slide"
        timeout={{ enter: 5000, exit: 5000 }}
      >
        {element}
      </CSSTransition>
    </TransitionGroup>
  )
}

Carousel.defaultProps = {
  className: [],
}

Carousel.propTypes = {
  className: PropTypes.array,
}
