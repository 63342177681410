import React from 'react'

import { Outlet, useLoaderData } from 'react-router-dom'

import { Helmet, HelmetProvider } from 'react-helmet-async'

import Footer from '../../components/Footer'
import Header from '../../components/Header'

import './styles.scss'

export default function App() {
  const data = useLoaderData()

  return (
    <>
      {/* <React.StrictMode> */}
        <HelmetProvider>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Pick a Tile</title>
          </Helmet>
          <Header />
          <Outlet />
          <Footer />
        </HelmetProvider>
      {/* </React.StrictMode> */}
    </>
  )
}

export async function appLoader({ request, params }) {
  return {
  }
  // return fetch(
  //   `/fake/api/teams/${params.teamId}.json`,
  //   { signal: request.signal }
  // )
}
