import React from 'react'

import './styles.scss'

export default function Cta({ title }) {
  return (
    <>
      {title}
    </>
  )
}
