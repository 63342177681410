import React, {  } from 'react'

import { NavLink as ReactRouterNavLink } from 'react-router-dom'

import './styles.scss'

const NavLink = (props) => <ReactRouterNavLink
  {...props}
  className={({ isActive, isPending }) =>
    isActive ? 'active' : isPending ? 'pending' : ''
  } />

export default function Header() {
  return (
    <header>
      <h1>Pick a Tile</h1>
      <nav>
        <NavLink to="/">Home</NavLink>
        <NavLink to="/faq">FAQ</NavLink>
        <NavLink to="/sneaky-peakies">Sneaky Peakies</NavLink>
        <NavLink to="/builds">Builds</NavLink>
        <NavLink to="/kb">KB</NavLink>
      </nav>
    </header>
  )
}
