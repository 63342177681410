import React from 'react'

import { useLoaderData } from 'react-router-dom'

import './styles.scss'

export default function InternalPage({ title }) {
  const data = useLoaderData()

  return (
    <>
      <h2>{title}</h2>
    </>
  )
}

export async function buildsLoader({ request, params }) {
  return {
  }
  // return fetch(
  //   `/fake/api/teams/${params.teamId}.json`,
  //   { signal: request.signal }
  // )
}

export async function faqLoader({ request, params }) {
  return {
  }
}

export async function sneakyPeakiesLoader({ request, params }) {
  return {
  }
}
