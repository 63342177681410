import React from 'react'

import './styles.scss'

export default function Hero() {
  return (
    <>
    </>
  )
}
