import React from 'react'

import './styles.scss'

export default function Footer() {
  return (
    <footer>
      <p>&copy; 2022-{new Date().toLocaleDateString('en-UK', {
        year: 'numeric',
      })} Pick a Tile. All rights reserved.</p>
    </footer>
  )
}
