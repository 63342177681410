import React from 'react'

import { useLoaderData } from 'react-router-dom'

import Carousel from '../../components/Carousel'
import Cta from '../../components/Cta'
import Hero from '../../components/Hero'

import './styles.scss'

export default function Home() {
  const data = useLoaderData()

  return (
    <>
      <Carousel className={['background']} />
      <Hero />
      <Cta title="FAQ" />
      <Cta title="Sneaky Peakies" />
      <Cta title="Builds" />
    </>
  )
}

export async function homeLoader({ request, params }) {
  return {
  }
  // return fetch(
  //   `/fake/api/teams/${params.teamId}.json`,
  //   { signal: request.signal }
  // )
}
