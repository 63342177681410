import React, { useEffect, useRef, useState } from 'react'

import PropTypes from 'prop-types'

import pick from 'lodash/pick'

import './styles.scss'

export default function Video(props) {
  const video = pick(props, [
    'autoPlay',
    'className',
    'loop',
    'muted',
    'poster',
    'preload',
  ])

  const source = pick(props, [
    'src',
    'type',
  ])

  return (
    <video {...video}>
      <source {...source} />
    </video>
  )
}

Video.defaultProps = {
  // <video>
  autoPlay: false,
  loop: false,
  muted: true,
  preload: 'metadata',
  // <source>
}

Video.propTypes = {
  // <video>
  autoPlay: PropTypes.bool,
  className: PropTypes.array,
  loop: PropTypes.bool,
  muted: PropTypes.bool,
  poster: PropTypes.string,
  preload: PropTypes.string,
  // <source>
  src: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}
