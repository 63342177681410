import React from 'react'
import ReactDOM from 'react-dom/client'

import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import reportWebVitals from './reportWebVitals'

import App, { appLoader } from './containers/App'

import Home, { homeLoader } from './routes/Home'
import InternalPage, {
  faqLoader,
  sneakyPeakiesLoader,
  buildsLoader
} from './routes/InternalPage'
import Kb, { kbLoader } from './routes/Kb'

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    loader: appLoader,
    children: [
      {
        path: '',
        element: <Home />,
        loader: homeLoader
      },
      {
        path: 'builds',
        element: <InternalPage title="Builds" />,
        loader: buildsLoader
      },
      {
        path: 'faq',
        element: <InternalPage title="Frequently Asked Questions" />,
        loader: faqLoader
      },
      {
        path: 'kb',
        element: <Kb />,
        loader: kbLoader
      },
      {
        path: 'sneaky-peakies',
        element: <InternalPage title="Sneaky Peakies" />,
        loader: sneakyPeakiesLoader
      },
    ],
  },
])

ReactDOM.createRoot(document.getElementById('root')).render(
  <RouterProvider router={router} />
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
